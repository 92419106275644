import { Controller } from "stimulus"
import Trix from "trix"
import Rails from "@rails/ujs"

const MAX_PARENT_SEARCH_DEPTH = 5;

/**
 * Controller for the table editor in trix.
 *
 * Code from https://onrails.blog/2020/09/30/adding-tables-to-actiontext-with-stimulus-js/.
 */
export default class extends Controller {
    /**
     * Adds a new row to the end of all rows to the table, by calling the rails action to add it
     * to the skelecton.
     * After the request was successfull, the attachment will be added to the dom.
     *
     * @param event
     */
    addRow(event) {
        Rails.ajax({
            url: `/intranet/trix_tables/${this.getID()}`,
            data: 'method=addRow' ,
            type: 'patch',
            success: (res) => this.attachTable(res)
        })
    }

    /**
     * Deletes the row having the rowIndex of the event target data from the table.
     * After the request was successfull, the attachment will be added to the dom.
     *
     * @param event
     */
    deleteRow(event) {
        let rowIndex;

        // This is the case if the icon was registered as event instead of the button
        // We need to fetch the rowIndex from the parent button instead
        if (event.target.tagName === 'I') {
            rowIndex = event.target.parentNode.dataset.rowIndex;
        }
        else {
            rowIndex = event.target.dataset.rowIndex;
        }

        if (!rowIndex) return;

        Rails.ajax({
            url: `/intranet/trix_tables/${this.getID()}`,
            data: `method=deleteRow&row_index=${rowIndex}`,
            type: 'patch',
            success: (res) => this.attachTable(res)
        })
    }

    /**
     * Deletes the column having the columnIndex of the event target data from the table.
     * After the request was successfull, the attachment will be added to the dom.
     *
     * @param event
     */
    deleteColumn(event) {
        let columnIndex;

        // This is the case if the icon was registered as event instead of the button
        // We need to fetch the columnIndex from the parent button instead
        if (event.target.tagName === 'I') {
            columnIndex = event.target.parentNode.dataset.columnIndex;
        }
        else {
            columnIndex = event.target.dataset.columnIndex;
        }

        if (!columnIndex) { return; }

        Rails.ajax({
            url: `/intranet/trix_tables/${this.getID()}`,
            data: `method=deleteColumn&column_index=${columnIndex}`,
            type: 'patch',
            success: (res) => this.attachTable(res)
        })
    }

    /**
     * Adds a new column to the end of all columns to the table, by calling the rails action to add it
     * to the skelecton.
     * After the request was successfull, the attachment will be added to the dom.
     *
     * @param event
     */
    addColumn(event) {
        Rails.ajax({
            url: `/intranet/trix_tables/${this.getID()}`,
            data: 'method=addColumn' ,
            type: 'patch',
            success: (res) => this.attachTable(res)
        })
    }

    /**
     * Updates the content of a cell in the table by calling the rails action to update the data in the skeleton.
     *
     * @param event
     */
    updateCell(event) {
        const rowIndex = event.target.dataset.rowIndex
        const columnIndex = event.target.dataset.columnIndex
        const value = encodeURIComponent(event.target.value)

        Rails.ajax({
            url: `/intranet/trix_tables/${this.getID()}`,
            data: `method=updateCell&row_index=${rowIndex}&column_index=${columnIndex}&value=${value}`,
            type: 'patch'
        })

        // because we called this in blur, the focus on the field is left and we should enable
        // trix buttons again
        this.enableTrixToolbar();
    }

    /**
     * Returns the ID of the table to identify it on server side.
     *
     * @returns {string}
     */
    getID() {
        return this.data.get('id');
    }

    /**
     * Adds the attachment to the trix editor.
     *
     * @param tableAttachment
     */
    attachTable(tableAttachment) {
        let attachment = new Trix.Attachment(tableAttachment)
        let parent = this.element.parentNode;
        let editorNode = null;
        for (let i = 0; i < MAX_PARENT_SEARCH_DEPTH; i++) {
            editorNode = parent.querySelector('trix-editor');
            if (editorNode != null) {
                i = MAX_PARENT_SEARCH_DEPTH;
            } else {
                parent = parent.parentNode;
            }
        }
        editorNode.editor.insertAttachment(attachment)
    }

    /**
     * Copies the text from the tooltip to the clipboard. Hence it can be inserted.
     *
     * @param event
     */
    copyToClipboard(event) {
        const toolTipText = event.target.title;
        if (!toolTipText) return;

        navigator.clipboard.writeText(toolTipText);
    }

    /**
     * Disables all buttons in the trix toolbar.
     * Should be done while editing the table, because pushing some format button will destroy formatting.
     */
    disableTrixToolbar() {
        const buttons = document.querySelectorAll('.trix-button');

        buttons.forEach((button) => {
            button.disabled = true;
        });
    }

    /**
     * Enables all buttons in the trix toolbar.
     * Should be done if the user does currently not edit the table.
     *
     * @param event
     */
    enableTrixToolbar() {
        const buttons = document.querySelectorAll('.trix-button');

        buttons.forEach((button) => {
            button.disabled = false;
        });
    }
}