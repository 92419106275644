import * as React from "react"

export interface DashboardCardProps {
    name: string;
    color: string;
    link: string;
}

/**
 * Component for a default dashboard card.
 */
export default class DashboardCard extends React.Component<DashboardCardProps, {}> {

    /**
     * React render method. Renders the view.
     * @returns {*}
     */
      render () {
        return  <div className='dashboard-card-container'>
                    <a className='dashboard-card-link'
                       href={`${this.props.link}`}>
                        <span className='dashboard-card-name'>{this.props.name}</span>
                    </a>
                </div>;
      }
}
