import { Controller } from "stimulus"
import Trix from "trix"
import Rails from "@rails/ujs"

let lang = Trix.config.lang;

/**
 * Adds a table button to the trix editor that creates a new table onclick.
 *
 * Code from https://onrails.blog/2020/09/30/adding-tables-to-actiontext-with-stimulus-js/.
 */
export default class extends Controller {

    /**
     * Called if the controller is connected to the DOM.
     * Appends the button to create a table to the trix editor.
     */
    connect() {
        Trix.config.lang.table = "Table"
        const tableButtonHTML = `<button type="button" class="trix-button trix-button--icon trix-button--icon-table" data-action="trix-table#attachTable" title="Tabelle einfügen" tabindex="-1">${lang.table}</button>`
        const fileToolsElement = this.element.querySelector('[data-trix-button-group=file-tools]')
        fileToolsElement.insertAdjacentHTML("beforeend", tableButtonHTML)
    }

    /**
     * Called if the button to add a table is clicked.
     * Generates a table record in rails and calls insertTable to add this to the dom.
     *
     * @param event
     */
    attachTable(event) {
        Rails.ajax({
            url: `/intranet/trix_tables`,
            type: 'post',
            success: this.insertTable.bind(this)
        })
    }

    /**
     * Inserts the tableAttachment to the trix editor.
     * Called after the table scelecton was created in rails.
     * @param tableAttachment
     */
    insertTable(tableAttachment) {
        this.attachment = new Trix.Attachment(tableAttachment)
        this.element.querySelector('trix-editor').editor.insertAttachment(this.attachment)
        this.element.focus()
    }
}