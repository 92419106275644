// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Sortable from 'sortablejs';
import "controllers"
Rails.start();

require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("select2");
require("jquery");
require("bootstrap");
require("data-confirm-modal");
require("floatthead");
require("cocoon");
/* Richt text editor */
require("trix");
require("@rails/actiontext");

require("sortablejs");
require("bootstrap-datepicker");


/**
 * Mounts the select2 field on the specified element.
 * If the field has the class taggable, adding options dynamicly to the
 * select2 field is activated.
 *
 * @param el
 */
function mountSelect2(el) {
  // enables adding names without the need they already exists
  const hasTags = $(el).hasClass('taggable');

  $(el).select2({
    theme: 'bootstrap4',
    tags: hasTags
  });
}

/**
 * Mounts all select2 fields on the page.
 */
function mountSelect2Fields() {
  /**
   * Initializes all select 2 fields that are marked as select2.
   */
  $('.select2-field').each((i, el) => {
    mountSelect2(el)
  });
}


/**
* Initializes select2 field for every field that has the class .select2-field.
*/
const onDocumentReady = () => {

  /**
   *  Start: Quelle: https://codepen.io/surjithctly/pen/PJqKzQ
   *
   *  Provides necessary callbacks to open the submenus on second level, because
   *  bootstrap only allowes submenus on first level.
   *  The submenus are also opened on the mobile view.
   */
  $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }
    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');

    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
      $('.dropdown-submenu .show').removeClass("show");
    });

    return false;
  });
  /* Ende: Quelle: https://codepen.io/surjithctly/pen/PJqKzQ */

  var newsId = 0;

  dataConfirmModal.setDefaults({
    title: 'Bist Du sicher?',
    commit: 'Ja',
    cancel: 'Abbrechen'
  });

  /*Drag and drop functionality for App-Table*/
  var tableSort = document.getElementById('appmanagement');
  if (tableSort != null) {
    Sortable.create(tableSort, {
      animation: 150,
      chosenClass: 'chosen',
      ghostClass: 'blue-background-class',

      /*Update order list when item is dropped*/
      onEnd: function (e) {
        updateAppOrder(e.item);
      }
    });
  }



/*
initialize calendar for survey deadline
*/

// set default format to day, month, year
$.fn.datepicker.defaults.format = "dd.mm.yyyy";

// include German translation
$.fn.datepicker.dates['de'] = {
  days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
  daysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
  daysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
  monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
  today: "Heute",
  monthsTitle: "Monate",
  clear: "Löschen",
  weekStart: 1,
  format: "dd.mm.yyyy"
};
// $('.datetime-deadline').datepicker({
//   // autoclose: true,
//   // format: "dd/mm/yyyy",
//   // autoclose: true,
//   // todayHighlight: true,
//   language: 'de'
// });

/*
Survey Tool: define section where to add questions in DOM
*/

  $(".addQuestion").
  data("association-insertion-method", 'append').
  data("association-insertion-node", '#question_section');

  /**
  Survey tool: add cool sorting mechanism for questions
  */
  $('#question_section').on('cocoon:after-insert', function() {
  // sortQuestions()
  });


  /**
    Editing survey -> give unique ids to button so that it toogles the corresponding options
  */
  function setIdsForQuestionView(){
    var questions = document.getElementsByClassName('question-nested-fields');
    for (var item of questions) {
      var i = Date.now();
      $(item).find('.btnsurvey').attr('data-target', `#a${i}`);
      $(item).find('.collapse').attr('id', `a${i}`);
      $(item).find('.surveyanswers_section').attr('id', `surveyanswers_section${i}`);
      $(item).find('.checkbox-deadline').attr('data-id', `${i}`);
      $(item).find('.datetime-deadline').attr('data-deadline-id', `${i}`);
      $(item).find('.addsa').data("association-insertion-method", 'append').
      data("association-insertion-node",`#surveyanswers_section${i}`);
      answerSort(`surveyanswers_section${i}`);
    }
  }
  setIdsForQuestionView();

  /*
  Survey tool:
  add data attributes as unique identifiers
  add animate fadeIn effect for new questions
  */
  $('#question_section')
  .on('cocoon:before-insert', function(e, insertedItem, originalEvent) {

    for (var item of insertedItem) {
      if (item.dataset.field == 'question') {
        var i = jQuery.now();
        $(insertedItem).addClass('animate__animated animate__fadeIn');
        $(insertedItem).find('.btnsurvey').attr('data-target', `#a${i}`);
        $(insertedItem).find('.collapse').attr('id', `a${i}`);
        $(insertedItem).find('.surveyanswers_section').attr('id', `surveyanswers_section${i}`);
        $(insertedItem).find('.checkbox-deadline').attr('data-id', `${i}`);
        $(insertedItem).find('.datetime-deadline').attr('data-deadline-id', `${i}`);
        $(insertedItem).find('.addsa').data("association-insertion-method", 'append').
        data("association-insertion-node",`#surveyanswers_section${i}`);

        var answerSection = $(insertedItem).find(`#surveyanswers_section${i}`);
        answerSection = answerSection[0];
        Sortable.create(answerSection, {
          animation: 150,
          chosenClass: 'chosen',
          ghostClass: 'blue-background-class'
        });
      }
      else {
        $(insertedItem).addClass('animate__animated animate__fadeIn');
      }
    }
  })
  .on('cocoon:after-insert', function(e, insertedItem){
    //after nested form has been insterted, create event listenter for deadline checkbox, so that users can use the deadline datepicker
    for (var item of insertedItem) {
      if (item.dataset.field == 'question') {
    $('.checkbox-deadline').change(function() {
      var id = $(this).attr('data-id')
      if($(this).is(':checked')) {
        $(`[data-deadline-id='${id}']`).removeAttr('disabled', 'disabled').addClass('cursor-pointer');
      } else {
        $(`[data-deadline-id='${id}']`).attr('disabled', 'disabled').removeClass('cursor-pointer');
      }

      })
    }
  }
  });

  $('.checkbox-deadline').change(function() {
    var id = $(this).attr('data-id');
    if($(this).is(':checked')) {
      $(`[data-deadline-id='${id}']`).removeAttr('disabled', 'disabled').addClass('cursor-pointer');
    } else {
      $(`[data-deadline-id='${id}']`).attr('disabled', 'disabled').removeClass('cursor-pointer');
    }

  });
  /*
  Survey tool: add cool sorting mechanism for answers
  */
  function answerSort( elementId) {
    var answerSection = document.getElementById(elementId);
    if (answerSection != null) {
      Sortable.create(answerSection, {
        animation: 150,
        chosenClass: 'chosen',
        ghostClass: 'blue-background-class'
      });
      }
  }

  mountSelect2Fields();

  /**
  * Applay fixed table headers to all tables having the table-fixed class.
  */
  $('.table-fixed').floatThead({
    responsiveContainer: function($table){
      return $table.closest('.table-responsive');
    }
  })

  /**
  * Updates the app order on the server side by calling the method to update the order.
  * The order is defined by the order of the apps displayed in the table of the given rowNode.
  * The table rows have to have the data attribute app-id to identify the apps on the server side.
  *
  * @param app_ids
  */
  const updateAppOrder = (rowNode) => {
    const tbodyNode = $(rowNode).parent();
    const app_ids = $.map(tbodyNode.children(), (row) => { return $(row).data("app-id") });

    Rails.ajax({
      url: "/apps/change_order",
      type: "POST",
      data: `app_ids=${JSON.stringify(app_ids)}`,
      success: function(data) {},
      error: function(data) { console.error("Fehler beim aktualisieren der Reihenfolge."); }
    })
  }

  /**
  * Used by the app index page.
  * Reorders the app in the overview.
  *
  * @param element
  */
  $('.apps-index-sort-button-up').click((e) => {
    const el = e.target;

    // find row that should be moved
    const sourceRowNode = $(el).closest('tr');
    if (!sourceRowNode) {
      return;
    }

    // load target row to swap
    const targetRowNode = $(sourceRowNode).prev();
    if (!targetRowNode) {
      return;
    }

    // reorder and change list order
    $(sourceRowNode).after(targetRowNode);

    // update on server side
    updateAppOrder(sourceRowNode);
  });

  /**
  * Used by the app index page.
  * Reorders the app in the overview.
  *
  * @param element
  */
  $('.apps-index-sort-button-down').click((e) => {
    const el = e.target;

    // find row that should be moved
    const sourceRowNode = $(el).closest('tr');
    if (!sourceRowNode) {
      return;
    }

    // load target row to swap
    const targetRowNode = $(sourceRowNode).next();
    if (!targetRowNode) {
      return;
    }

    // reorder and change list order
    $(sourceRowNode).before(targetRowNode);

    // update on server side
    updateAppOrder(sourceRowNode);
  });

  /**
  * Changes the text of custom file picker labels to the name of the selected file.
  */
  $('.custom-file-input').change(function (event) {
    $(this).next('.custom-file-label').html(event.target.files[0].name);
  });

  /**
   * Returns a string holding the range of start_date and end_date of the specified event json.-
   * If the dates are on the same day, only the time will be displayed for the end date.
   *
   * @param event
   */
  const eventDateString = (event) => {
    const startDate = event['start_date'] ? new Date(event['start_date']) : null;
    const endDate = event['end_date'] ? new Date(event['end_date']) : null;

    // no date available
    if (!startDate && !endDate) return ''

    // only one date available
    const DATETIME_OPTIONS = { year: "2-digit", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"};
    const TIME_OPTIONS = { hour: "2-digit", minute: "2-digit"};

    if (startDate && !endDate) {
      return startDate.toLocaleString('de-DE', DATETIME_OPTIONS)
    } else if (!startDate && endDate) {
      return endDate.toLocaleString('de-DE', DATETIME_OPTIONS)
    }

    // both available, both on same day
    if (startDate.toDateString() === endDate.toDateString()) {
      return `${startDate.toLocaleString('de-DE', DATETIME_OPTIONS)} - ${endDate.toLocaleString('de-DE', TIME_OPTIONS)}`;
    }

    // both available, different days
    return `${startDate.toLocaleString('de-DE', DATETIME_OPTIONS)} - ${endDate.toLocaleString('de-DE', DATETIME_OPTIONS)}`;
  }

  /**
   * Returns a HTML view holding the range of start_date and end_date of the specified event json.-
   * If the dates are on the same day, only the time will be displayed for the end date.
   *
   * @param event
   */
  const eventDateHTML = (event) => {
    const startDate = event['start_date'] ? new Date(event['start_date']) : null;
    const endDate = event['end_date'] ? new Date(event['end_date']) : null;

    // no date available
    if (!startDate && !endDate) return ''

    // only one date available
    const DATE_OPTIONS = { year: "2-digit", month: "2-digit", day: "2-digit"};
    const TIME_OPTIONS = { hour: "2-digit", minute: "2-digit"};

    if (startDate && !endDate) {
      return `
        <div class="d-flex flex-column">
          <div>
              ${startDate.toLocaleDateString('de-DE', DATE_OPTIONS)}
          </div>
          <div>
              ${startDate.toLocaleTimeString('de-DE', TIME_OPTIONS)}
          </div>
        </div>
      `;
    } else if (!startDate && endDate) {
      return `
        <div class="d-flex flex-column">
          <div>
              ${endDate.toLocaleDateString('de-DE', DATE_OPTIONS)}
          </div>
          <div>
              ${endDate.toLocaleTimeString('de-DE', TIME_OPTIONS)}
          </div>
        </div>
      `;
    }

    // both available, both on same day
    if (startDate.toDateString() === endDate.toDateString()) {
      return `
        <div class="d-flex flex-column">
          <div>
              ${startDate.toLocaleDateString('de-DE', DATE_OPTIONS)}
          </div>
          <div>
              ${startDate.toLocaleTimeString('de-DE', TIME_OPTIONS)} - ${endDate.toLocaleTimeString('de-DE', TIME_OPTIONS)}
          </div>
        </div>
      `;
    }

    // both available, different days
    return `
      <div class="d-flex flex-column">
          <div class="font-size-very-small">Start:</div>
          <div class="d-flex flex-column">
            <div>
                ${startDate.toLocaleDateString('de-DE', DATE_OPTIONS)}
            </div>
            <div>
                ${startDate.toLocaleTimeString('de-DE', TIME_OPTIONS)}
            </div>
          </div>
          <div class="font-size-very-small mt-1">Ende:</div>
          <div class="d-flex flex-column">
            <div>
                ${endDate.toLocaleDateString('de-DE', DATE_OPTIONS)}
            </div>
            <div>
                ${endDate.toLocaleTimeString('de-DE', TIME_OPTIONS)}
            </div>
          </div>
      </div>
    `;
   }

  /**
  * Fills the notification modal content with the specified content and title and links
  * to edit and destroy the notification.
  */
  const setNotificationModal = (title, content, editLink, destroyLink, comments, surveys, events) => {
    const modal = $(`#notification-modal`);
    $(modal).find('.modal-body').html(content);
    $(modal).find('.modal-title').html(title);

    // Add events to the view
    if (events && events.length > 0) {
      // add inner container to be able to margin without conflicting the other content
      $("#notification-modal-events-container").append("<div id='notification-modal-events-inner-container' class='m-3'></div>")

      // add heading
      const eventsContainer = $('#notification-modal-events-inner-container')
      eventsContainer.append("<h6>Termine</h6>")

      // add all events
      const eventsList = eventsContainer.append('<div id="notification-modal-events-list" class="d-flex flex-column gap-2"></div>')
      events.forEach((event) => {
        eventsList.append(`
            <div class="d-flex flex-column">
               <div class="d-flex flex-row gap-1">
                  <div><i class="fas fa-calendar-check mr-1"></i>${eventDateString(event)}</div>
                  ${event['name'] ? '<div>' + event['name'] + '</div>' : ''}
                  ${event['location'] ? '<div><i class="fas fa-map-marker mr-1"></i>' + eventLocationHTML(event['location']) + '</div>' : ''}
               </div>
             </div>
        `)
      })
    } else {
      $('#notification-modal-events-container').empty()
    }
/*
  Check if survey property is empty. If not, show surveys.
*/
    if (surveys != null && surveys.length > 0) {
      $( "<h5 class='modal-survey-titel titel'> Umfrage </h5>" ).insertBefore( ".questionaire-titel" );
      /*
        Iterate through survey array in order to access each survey
      */
      var counter = 1;
      var surveyArrayLength = surveys.length;
      surveys.forEach(function(survey){
        /*
          Add survey to DOM
        */
        $(modal).find('.questionaire-titel').append(
        `
          <div class='box'>
            <div class='question-number my-auto mx-auto text-center' data-question-id='${survey.id}'>${counter}</div>
            <div class='vertical-line' id='vertical-line-${survey.id}'></div>
          </div>
          <div class='question-block-${survey.id}'>
            <div class='question d-flex mb-2'>
            <div>
              <h6 class='mt-1'> ${survey.name} </h6>
              <div><em> ${survey.description} </em></div>
            </div>
            <div class='ml-auto' id='survey-change-button-${survey.id}'>
            </div>
            </div>
            <div class='answers mb-2' id='answer-${survey.id}'>
            </div>
            <div class='' id='answer-deadline-${survey.id}'></div>
          </div>
            `
          );
          /*
          If user is admin (survey.changes_votes_url is not null), display button for change_votes page
          */
          if (survey.change_votes_url){
            $(`#survey-change-button-${survey.id}`).append(`<a class='btn btn-secondary text-uppercase' href="${survey.change_votes_url}"> <i class="fas fa-poll"></i> Ergebnis</a>`)
          }

          /*
          If survey deadline is present, display it and/or make answer fields disabeld/enabled
          */

          if (survey["deadline"]){
            var deadlineDate = new Date(survey.deadline);

            if(Date.now() >= deadlineDate) {
              // Deadline is overdue
              var disabled = 'disabled';
              $(modal).find(`#answer-deadline-${survey.id}`).addClass('answer-deadline')
              $(modal).find(`#answer-deadline-${survey.id}`).append(`Die Frage kann nicht mehr beantwortet werden.`)

            }
            else {
              // Deadline was not reached yet
              $(modal).find(`#answer-deadline-${survey.id}`).addClass('answer-deadline')
              $(modal).find(`#answer-deadline-${survey.id}`).append(`Bitte beantworten bis: ${new Date(survey.deadline).toLocaleDateString()}`)
            }
          }
          /*
            Iterate through answer array in survey array in order to access each survey
          */

          const survey_answer = $(modal).find(`#answer-${survey.id}`);
          if(survey.multiple_choice == false) {// check if user can not vote for only one option - single vote
            survey.survey_answers.forEach(function(answer){
              var checked;
              survey.current_user_votes.forEach(function(array){

                /*
                Check if answer id exists in survey current votes -> which means user has voted for this answer already. Mark the answer as checked
                */

                if (array == answer.id) {
                  checked = 'checked';
                  $(`[data-question-id=${survey.id}]`).attr('checked','checked');
                  $(`[data-question-id=${survey.id}]`).css('background-color','#3288a4');
                  $(`[data-question-id=${survey.id}]`).html('<i class="text-white fas fa-check"></i>');
                  // Single vote: write current checked vote url into DOM since there is no event listener in case the user votes for another option and the current option gets unchecked
                  $(`#answer-${survey.id}`).attr('data-uncheck-link', `${answer.vote_url}.json`);
                }

              });
              /*
                Add answers to DOM
              */
              survey_answer.append(
                `
                <div class="form-check">
                  <input class="survey-vote form-check-input option-input checkbox" type="radio" value="${answer.name}" id="radio-answer-${answer.id}" name="radio-${survey.id}" data-link="${answer.vote_url}" data-answer-id='${survey.id}' ${disabled} ${checked}>
                  <label class="form-check-label" for="radio-answer-${answer.id}"> ${answer.name} </label>
                </div>
                `
              );
            });

          }
          /*
          if multiple choice is true, run the else clause
          */
          else {
            survey.survey_answers.forEach(function(answer){
              var checked;
              survey.current_user_votes.forEach(function(array){
                if (array == answer.id) {
                  checked = 'checked';
                  $(`[data-question-id=${survey.id}]`).attr('checked','checked');
                  $(`[data-question-id=${survey.id}]`).css('background-color','#3288a4');
                  $(`[data-question-id=${survey.id}]`).html('<i class="text-white fas fa-check"></i>');
                }

              });


            survey_answer.append(
              `
              <div class="form-check">
                <input class="survey-vote form-check-input" type="checkbox" value="${answer.name}" id="checkbox-answer-${answer.id}" name="checkbox" data-link="${answer.vote_url}" data-answer-id='${survey.id}' ${disabled} ${checked}>
                <label class="form-check-label" for="checkbox-answer-${answer.id}"> ${answer.name} </label>
              </div>
              `
            );

          });

          }
          if(survey.answers_user_addable) {
              $( `<div class='form-group d-flex'>
              <div class='input-wrapper'>
              <input class='addable-answer' type='text' placeholder='Füge eine Antwort hinzu..' data-answer-add-id='${survey.create_answer_url}'></input><i class="far fa-paper-plane" id='send-new-answer' data-answer-send-id='${survey.create_answer_url}'></i></div>
                  </div>` ).insertBefore(`#answer-deadline-${survey.id}`);

                  attachAnswerUserAddableListener(survey.create_answer_url);
          }

          if (counter == surveyArrayLength) {
            $(`#vertical-line-${survey.id}`).css('border', 'none');

          }
          counter = counter +1;
            });

            attachSurveyListener();
      }
      /*
      !!!Need to be checked if this still makes sense!!!
      */
      else {
        $(modal).find('.questionaire-titel').empty();

      }

    if (comments != null) {
      writeComments(comments);
    }

    if (editLink && $('#notification-modal-edit-link').length) {
      $('#notification-modal-edit-link').attr("href",editLink);
    }
    if (destroyLink && $('#notification-modal-destroy-link').length) {
      $('#notification-modal-destroy-link').attr("href", destroyLink);
    }
  };
  function attachSurveyListener(){
    $('.survey-vote').off('change');

    $('.survey-vote').change(function(){
      var url = $(this).attr('data-link') + '.json';
      var surveyId = $(this).attr('data-answer-id');
      var value = $(this).attr('value');
      var uncheck_link;


      if($(this).is(':checked')) {
        if ($(`[data-question-id=${surveyId}]`).attr('checked') != 'checked'){
          $(`[data-question-id=${surveyId}]`).attr('checked','checked');
          $(`[data-question-id=${surveyId}]`).css('background-color','#3288a4');
          $(`[data-question-id=${surveyId}]`).html('<i class="text-white fas fa-check animate__animated animate__zoomIn"></i>')
      }
      if ($(this).attr('type') == 'radio'){
        uncheck_link = document.getElementById(`answer-${surveyId}`).dataset.uncheckLink;
      }
      if ($(this).attr('type') == 'radio' && uncheck_link) {
        Rails.ajax({
          url: uncheck_link,
          type: 'DELETE',
          success: function(data) {
            Rails.ajax({
              url: url,
              type: 'POST',
              success: function(data) {

                $(`#answer-${surveyId}`).attr('data-uncheck-link',`${url}`);
              },
              error: function(data) {customError();

              }
            })
        },
        error: function(data) {  customError();
        }
      })
    }
    else {
        Rails.ajax({
          url: url,
          type: 'POST',
          success: function(data) {
            $(`#answer-${surveyId}`).attr('data-uncheck-link',`${url}`);
        },
        error: function(data) {  customError();

        }
      });
    }
  }
      else {
        Rails.ajax({
          url: url,
          type: 'DELETE',
          success: function(data) {
      },
      error: function(data) {  customError();
      }
    });
  }
});

}

const attachAnswerUserAddableListener = (url) => {
  $(`[data-answer-add-id='${url}']`).on('keypress', function(e){
    $(this).parent().removeClass('is-invalid');
    var val = this.value;
    if (e.which === 13){
      if (val==='') {
        $(this).parent().addClass('is-invalid')
        return;
      }
      createNewAnswer(url,val);
}
});
$(`[data-answer-send-id='${url}']`).on('click', function(e){
  var val = $(`[data-answer-add-id='${url}']`).val();
  $(this).addClass('click');
    if (val==='') {
      $(`[data-answer-add-id='${url}']`).parent().addClass('is-invalid')
      return;
    }
createNewAnswer(url,val);
});

$(`[data-answer-send-id='${url}']`).on('mouseleave', function(e){
  $(this).removeClass('click');

});


};

const createNewAnswer = (url,val) => {
  Rails.ajax({
    url: `${url}.json`,
    data: `survey_answer[name]=${val}`,
    type: 'POST',
    success: function(data) {

      if(data.multiple_choice) {
        $(`#answer-${data.id}`).append(
          `
          <div class="form-check">
            <input class="survey-vote form-check-input" type="checkbox" value="${data.name}" id="checkbox-answer-${data.id}" name="checkbox" data-link="${data.vote_url}" data-answer-id='${data.id}'>
            <label class="form-check-label" for="checkbox-answer-${data.id}"> ${data.name} </label>
          </div>
          `
        )
        attachSurveyListener();

      }

      else {
        $(`#answer-${data.id}`).append(
          `
        <div class="form-check">
          <input class="survey-vote form-check-input option-input checkbox" type="radio" value="${data.name}" id="radio-answer-${data.id}" name="radio-${data.id}" data-link="${data.vote_url}" data-answer-id='${data.id}'>
          <label class="form-check-label" for="radio-answer-${data.id}"> ${data.name} </label>
        </div>
        `)
        attachSurveyListener();
      }
      $(`[data-answer-add-id='${url}']`).val('');
},
error: function(data) {
  customError();
}
});

}

  /*Write all comments into DOM/modal*/

  function writeComments(comments) {
    const modal = $(`#notification-modal`);

    /*Read comments parameter - only if comments are not empty*/
    $(modal).find('.modal-notification-content').empty();
    /*Parse comments parameter and add its content to the DOM*/
    let id = 0;
    comments.forEach(function(comment) {
      let destroyIcon = '';
      if(comment.destroy_link != null) {
        destroyIcon = `<div class='text-center destroy-link mr-3'><i class='far fa-trash-alt cursor-pointer transition-smooth delete-comment-icon' data-link-destroy='${id}' data-attr=${comment.destroy_link} ></i></div>`
      }

      let avatarHtml = `<canvas class='user-canvas mr-2' id='user-icon-${id}' width='50' height='50'></canvas>`;
      if (comment.avatar_url) {
        avatarHtml = `<img class='avatar' src='${comment.avatar_url}' />`
      }

      $(modal).find('.modal-notification-content').append(
        `<div class='mb-4' data-box-id='${id}'>
          <div class='comment-box d-flex align-items-center' data-add='${id}'>
            ${avatarHtml}
            <div class='comments'>
              <div class='modal-notification-username ml-1'>  ${comment.username} </div>
              <div class='comment-text ml-1'>${comment.content}</div>
            </div>
            <div class='modal-notification-update ml-auto' data-update-box-id=${id}>
              <div class='data-time mr-3' data-updated-time='${id}'>${comment.updated_at}</div>
              ${destroyIcon}
            </div>
          </div>
        </div>`
      );
        var colours = ["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50", "#f1c40f", "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d"];

        var name = comment.username;
        var nameSplit = name.split(" ");
        var initials = nameSplit[0].charAt(0).toUpperCase();

        if(nameSplit.length > 1) {
          initials = initials + nameSplit[nameSplit.length-1].charAt(0).toUpperCase();
        }

        var charIndex = initials.charCodeAt(0) - 65;
        var colourIndex = charIndex % 19;

        var canvas = document.getElementById("user-icon-" + id);
        if (canvas) {
          var context = canvas.getContext("2d");

          var canvasWidth = $(canvas).attr("width");
          var canvasHeight = $(canvas).attr("height");
          var canvasCssWidth = canvasWidth;
          var canvasCssHeight = canvasHeight;

          var fontArgs = context.font.split(' ');
          var newSize = '25px';
          var textFont = newSize + ' ' + fontArgs[fontArgs.length - 1];

          /*draw user symbols (circle)*/
          context.arc(canvasHeight/2,canvasWidth/2, canvasHeight/2, 0, 2 * Math.PI);
          context.fillStyle = colours[colourIndex];
          context.fill();
          context.fillStyle = '#FFF';
          context.font = textFont;
          context.textAlign = "center";
          context.fillText(initials, canvasCssWidth/2, canvasCssHeight/1.5);
        }

        id = id+1;
      });

      attachDeleteIconListeners();
    }


    /**
      Create new comment by user. If comment inputfield is empty, display it as invalid.
      If not, write into db and render all comment.
    */
    function createComment() {
      $('#comment-input-field').css('opacity','0.5');
      var comment = $('#comment-input-field').val();
      $('#comment-create').removeClass('btn-primary').addClass('btn-secondary');
      $('#loading-comments').removeClass('d-none');
      Rails.ajax({
        url: `/notifications/${newsId}/comments.json`,
        type: 'POST',
        data: `notification_comment[content]=${comment}`,
        success: function(data) {

        // Get the content
        Rails.ajax({
          url: `/notifications/${newsId}/json`,
          type: "GET",
          success: function(data) {
            writeComments(data.comments);
            $('#comment-input-field').val('');
            $('#loading-comments').addClass('d-none');
            $('#comment-input-field').css('opacity','1');
            $('#comment-create').removeClass('btn-secondary').addClass('btn-primary');
          },
          error: function(data) {
            customError('Beim Ladem der Kommentare ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.');
            $('#loading-comments').addClass('d-none');
            $('#comment-input-field').css('opacity','1');
            $('#comment-create').removeClass('btn-secondary').addClass('btn-primary');

    }
        });
      },
      error: function(data) {
        customError('Beim Speichern des Kommentars ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.');
        $('#loading-comments').addClass('d-none');
        $('#comment-input-field').css('opacity','1');
        $('#comment-create').removeClass('btn-secondary').addClass('btn-primary');
}
    });
  }

/* Click event listener for creating a comment*/
  $('#comment-create').click(function(){

    const inputField = $('#comment-input-field');
    if (inputField.val() === '') {
      inputField.addClass('is-invalid')
      return;
    }

    if(checkInputLength(inputField.val())) {
      return;
    }
    createComment();
  });

  function checkInputLength(input) {
    if (input.length > 800) {
      $('#comment-input-field').addClass('is-invalid');
      $('.comment-error').html(`<div class='text-center animate__animated animate__fadeIn' style='color: red; margin-top: -1rem;'>Der Kommentar darf nur maximal 800 Zeichen lang sein.</div>`);
      return true;
    }
    else {
      $('.comment-error').html('');
      return false;
    }
  }

  /* Keypress event listener for creating a comment*/

  $('#comment-input-field').keypress(function(e){
    $(this).removeClass('is-invalid');

    if (e.which === 13){
      if ($(this).val() === '') {
        $('#comment-input-field').addClass('is-invalid')
        return;
      }
      if(checkInputLength(this.value)) {
        return;
      }
      createComment();
    }
  });

  /**
  Attaches onclick events to all delete button icons.
  On click event in case user clicks on comment deletion icon. It creates the actual delete buttons and passes the delete link json url
  */
  function attachDeleteIconListeners() {
    const deleteIcons = $('.delete-comment-icon');

    // First remove old listeners to prevent them from being added twice
    deleteIcons.off('click');

    // Add new one
    deleteIcons.click(function(){
      const id = $(this).attr('data-link-destroy');
      let deleteLink = $(this).attr('data-attr');
      deleteLink = deleteLink + '.json';

      $(`[data-update-box-id=${id}]`).addClass('d-none');

      $(`[data-add=${id}]`).append(
          `
      <div id='deletion-confirmation-buttons-${id}' class='ml-auto d-flex text-center destroy-icon-comments animate__animated animate__slideInRight animate__slow cursor-pointer'>
      <div class='bg-danger'>
        <i class='p-2 far fa-trash-alt'></i>
      </div>
        <div class='bg-danger check'>
          <i id='confirm-deletion-comment' data-attr-delete='${deleteLink}' data-id='${id}' class="p-2 text-white fas fa-check"></i>
        </div>
        <div class='bg-dark decline'>
          <i id='decline-deletion-comment' data-id='${id}' class="p-2 fas fa-times"></i>
        </div>
      </div>
      `);

      attachDeleteConfirmationListeners();
    });
  }


  /**
    Attaches the listeners to the delete confirmation for comments.
  */
  function attachDeleteConfirmationListeners() {

    // first remove the old listeners to prevent them from being loaded twice
    $('#confirm-deletion-comment').off('click');
    $('#decline-deletion-comment').off('click');

    /**
     *  on click event to delete comments if clicked
     */
    $('#confirm-deletion-comment').click(function(){

      var deleteLink = $(this).attr('data-attr-delete');
      Rails.ajax({
        url: deleteLink,
        type: "DELETE",
        success: function(data) {
          $(`[data-box-id=${$(this).attr('data-id')}]`).addClass('animate__animated animate__fadeOut');
          // Render all comments
          Rails.ajax({
            url: `/notifications/${newsId}/json`,
            type: "GET",
            success: function(data) {
              writeComments(data.comments);
            }
          });
          $(`#deletion-confirmation-buttons-${$(this).attr('data-id')}`).removeClass('animate__animated animate__slideInRight');
          $(`#deletion-confirmation-buttons-${$(this).attr('data-id')}`).addClass('animate__animated animate__slideOutRight');

        },
        error: function(data) {
          customError();
        }
      });

    });

    /*
    On click event to close the comments deletion buttons
    */
    $('#decline-deletion-comment').click(function(){
      const confirmationButton = $(`#deletion-confirmation-buttons-${$(this).attr('data-id')}`);
      confirmationButton.removeClass('animate__animated animate__slideInRight');
      confirmationButton.addClass('animate__animated animate__slideOutRight');
      setTimeout(() => {$(`#deletion-confirmation-buttons-${$(this).attr('data-id')}`).remove();}, 1000);
      setTimeout(() => {$(`[data-update-box-id=${$(this).attr('data-id')}]`).removeClass('d-none');}, 1200);
      setTimeout(() => {$(`[data-update-box-id=${$(this).attr('data-id')}]`).addClass('animate__animated animate__fadeIn');}, 1200);
    });
  }

  /**
   * Called if the user clicks on a notification. Opens the notification modal showing all information.
   * @param event
   */
  function onClickNotification(event) {
    // check if clicked target is edit or delete button, if so stop the click event
    const target = $( event.target );
    if ( target.hasClass( "header-bar-icon-link" ) || target.hasClass( "fa-trash-alt" ) ) {
      return;
    }

    let id = $(this).data('notification-id');
    newsId = id;

    const notificationModal = $('#notification-modal');

    // set loading spinner
    setNotificationModal("", "<div class='text-center'>\n" +
        "          <div class=\"sk-cube-grid\">\n" +
        "            <div class=\"sk-cube sk-cube1\"></div>\n" +
        "            <div class=\"sk-cube sk-cube2\"></div>\n" +
        "            <div class=\"sk-cube sk-cube3\"></div>\n" +
        "            <div class=\"sk-cube sk-cube4\"></div>\n" +
        "            <div class=\"sk-cube sk-cube5\"></div>\n" +
        "            <div class=\"sk-cube sk-cube6\"></div>\n" +
        "            <div class=\"sk-cube sk-cube7\"></div>\n" +
        "            <div class=\"sk-cube sk-cube8\"></div>\n" +
        "            <div class=\"sk-cube sk-cube9\"></div>\n" +
        "          </div>\n" +
        "        </div>", null, null, null, null, null);

    $('#notification-modal').find('.modal-survey-titel').remove();
    $('#notification-modal').find('.modal-notification-content').empty();

    // $('#notification-modal').find('questionaire-titel').empty();

    if (!id) {
      return;
    }

    // Set the notification to be read
    Rails.ajax({
      url: `/notifications/${id}/set_read_at`,
      type: "PATCH"
    });

    // Get the content
    Rails.ajax({
      url: `/notifications/${id}/json`,
      type: "GET",
      success: function(data) {
        if (data.content && data.name) {
          setNotificationModal(data.name, data.content, data.edit_link, data.destroy_link, data.comments, data.surveys, data.events);
        }
      }
    });

    notificationModal.modal();

    // remove badge from notification in header
    if ($(this).find('.badge').length > 0) {
      $(this).find('.badge').remove();

      // Update number of unread notifications
      const unreadCount = parseInt($("#notification-number").html());
      if (unreadCount === 1) {
        $('#notification-number').remove();
      }
      else {
        $("#notification-number").html(unreadCount - 1)
      }
    }

    // If this is a list item, change the envelope icon
    if ($(this).find('.list-badge').length > 0) {
      const icon = $(this).find('.list-badge i.fa-envelope')
      icon.removeClass('fa-envelope')
      icon.removeClass('text-danger')
      icon.addClass('fa-envelope-open')
    }
  }

  /**
   * Open modal that includes the just clicked news
   */
  $('.news-trigger').click(onClickNotification);

  /**
  * hover for admin view of news archive.
  */
  $( ".card-news-archive" ).hover(
    function() {
      $(this).find( '.admin-view-notifications' ).addClass( "admin-view-notifications-hover animate__animated animate__fadeIn" );
    }, function() {
      $(this).find( '.admin-view-notifications' ).removeClass( "admin-view-notifications-hover animate__animated animate__fadeIn" );
    }
  );

  // When the user scrolls down 20px from the top of the document, show a button so that the user can scroll automatically to the top.
  window.onscroll = function() {scrollFunction()};
  function scrollFunction() {
    var mybutton = document.getElementById("button_top");
    if (mybutton != null) {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }
  }

/*
Custom function to display a danger alert on any page. Alert can have
 a message -> customError(message)
 a titel and message -> customError(titel, message)
*/
  const customError = (titelString, messageString) => {
    var titel = '<i class="far fa-frown-open"></i> Da ist was schief gelaufen';
    var message = 'Bitte versuche es später noch einmal.';


    if(titelString && !messageString) {
      message = titelString
    }
    else if (titelString && messageString){
      titel = titelString
      message = messageString
    }

    const id = Date.now()
    $('#error-placement').append(`
      <div class="alert alert-primary alert-danger error-message alert-dismissible animate__animated animate__fadeInUp" role="alert" id='${id}'>
        <h4 class="alert-heading">${titel}</h4>
            <hr>
        <p>${message}</p>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      </div>
  `);
    setTimeout(() => {$(`#${id}`).addClass('animate__animated animate__fadeOutDown');}, 5000);
    setTimeout(() => {$(`#${id}`).remove();}, 5500);
  };

  /*
  Custom function to display a warning alert on any page. Alert can have
   a message -> customError(message)
   a titel and message -> customError(titel, message)
  */
  const customWarning = (titelString, messageString) => {
    var titel = '<i class="far fa-frown-open"></i> Da ist was schief gelaufen';
    var message = 'Bitte versuche es später noch einmal.';


    if(titelString && !messageString) {
      message = titelString
    }
    else if (titelString && messageString){
      titel = titelString
      message = messageString
    }

    const id = Date.now();
    $('#error-placement').append(`
      <div class="alert alert-primary alert-warning error-message animate__animated animate__fadeInUp" role="alert" id='${id}'>
        <h4 class="alert-heading">${titel}</h4>
            <hr>
        <p>${message}</p>
      </div>
  `);
    setTimeout(() => {$(`#${id}`).addClass('animate__animated animate__fadeOutDown');}, 6000);
    setTimeout(() => {$(`#${id}`).remove();}, 6500);
  };


  /* SUPER WEIRD STUFF THAT NOBODY KNOWS WHAT IT DOES*/
  $('.footer-nice-link').click(function(){
    var content = `<div class="container">

	<div class="bird-container bird-container--one">
		<div class="bird bird--one"></div>
	</div>

	<div class="bird-container bird-container--two">
		<div class="bird bird--two"></div>
	</div>

	<div class="bird-container bird-container--three">
		<div class="bird bird--three"></div>
	</div>

	<div class="bird-container bird-container--four">
		<div class="bird bird--four"></div>
	</div>

</div>

`;
    $('.niiice').html(content);
    setTimeout(() => {$(".niiice").empty();}, 5000);
  });

  // When the user clicks on the button, scroll to the top of the document
  $('#button_top').click(function(){
    window.scrollTo({top: 0, behavior: 'smooth'});
  });

  // Called if a survey was added, adds one answer per default
  $('#surveys').on('cocoon:after-insert', function(e, addedSurvey) {
    if ($(addedSurvey).data('field') !== 'question') {
      return;
    }

    const addButton = $(addedSurvey).find('#survey_answers > div > a.add_fields');
    if (addButton && addButton.length === 1) {
      addButton.click();
    }
  });

/*
Trix-editor event listener: Forbid trix uploads bigger than 1Mb
*/
  $('trix-editor').on('trix-file-accept', function() {

    if (event.file.size > 10485760) {
        event.preventDefault();
        customWarning('Dateiupload','Es dürfen nur Dateien mit max. 10 Mb hochgeladen werden.');
      }
  });

  /*
Trix-editor event listener: Remove files that has been added via copy and paste since this leads to an issue in active storage
  */

    $('trix-editor').on("trix-attachment-add", function(event) {
      if (!event.attachment.file) {
        event.attachment.remove();
        customWarning('Dateiupload','Bilder bitte manuell über den Button hinzufügen');
      }
    });

  /**
   * Returns the tabId of the specified tab content element.
   * @param element
   */
  const extractTabIdFromElement = (element) => {
    return parseInt($(element).attr('id').replace( /^\D+/g, ''));
  }

  /**
   * Can be called from listeners that react on change on a tab title input field.
   * Changes the related tabs name.
   * The e parameter is the expected to be the change event.
   *
   * @param e
   */
  function onTabTitleChange(e) {
    // find the id of the tab
    const tabIdHoldingParent = $(e.target).closest('.tab-pane.show');
    const tabId = extractTabIdFromElement(tabIdHoldingParent);

    $(`#article-edit-tab-${tabId}-tab`).html(e.target.value);
  }

    /**
     * Subscribe the onTabTitleChange listener to existing tabs. (necessary for update action)
     */
      $('.tab-pane.show').each(function() {
          $(this).find("input[name$='[title]']").first().keyup(function (e) {
              onTabTitleChange(e);
          });
      })

  /**
     * Called if before a new article tab gets inserted in the lms.
     * Sets an pseudo id to the inserted item and adds a tab to select those item.
     * Also activates the new inserted tab as the selected one.
     */
    $('#article_tabs').on('cocoon:before-insert', function(e, insertedItem, originalEvent) {
      // Only for tabs, no other cocoon nested form
      if (!$(insertedItem).hasClass('article-tab-container')) return;

      // find last id
      const allTabElements = $("a[id^='article-edit-tab-']");
      let newTabId = 1;
      allTabElements.each(function () {
        // extract number from id
        let tabElementId = $(this).data('tabid')
        if (tabElementId > newTabId) {
          newTabId = tabElementId;
        }
      });
      newTabId++;

      // set new id
      $(insertedItem).attr('id', `article-edit-tab-${newTabId}`);

      // deselect old tabs, because we want to have the new one selected
      $('.nav-link.active').removeClass('active')
      $('.tab-pane.show.active').removeClass('active')

      // Add tab to list to target the nested form item
      $('#fake-add-article-tab-button').before(
          `
          <li class="nav-item">
            <a class="nav-link active"
               id="article-edit-tab-${newTabId}-tab"
               data-toggle="tab"
               href="#article-edit-tab-${newTabId}"
               data-tabid="${newTabId}"
               role="tab">
              Neuer Tab
            </a>
          </li>
          `
      )

      // set new tab as active
      $(insertedItem).addClass('active')

      // Register listener for title change to change the tab title
      $(insertedItem).find("input[name$='[title]']").first().keyup(function (e) {
        onTabTitleChange(e);
      })
    });

    /**
     * Called if an article tab gets removed.
     * Removes the tab from the tabbar behind the removedItem.
     */
    $('#article_tabs').on('cocoon:after-remove', function(e, removedItem, originalEvent) {
      // Only for tabs, no other cocoon nested form
      if (!$(e.target).hasClass('article-tab-container')) return;

      // tabId was set to the id on insert (see hook above)
      const tabId = parseInt(extractTabIdFromElement(e.target));

      $(`#article-edit-tab-${tabId}-tab`).remove()
    });

  /**
   * This is a small hack to replace the position of the cocoons add button.
   * It seems to be the case, that cocoon needs the add button at a specific position in the items container.
   * But we want to have the button in the tabs list at the end.
   * Hence we build a fake button that redirects the click to the real button.
   */
  $('#fake-add-article-tab-button').click(function () {
      $('#real-add-article-tab-button').click();
  });

  const MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']

  /**
   * Returns html for an event location that can be used in event show actions.
   * Used to find hyperlinks and surround them by an a tag.
   *
   * @param eventLocation
   */
  const eventLocationHTML = (eventLocationText) => {
    if (!eventLocationText) return eventLocationText;

    return eventLocationText.replace(/(http(s)?:\/\/.+?)(\s|$)/, '<a href="$1" class="link-highlight" target="_blank">$1</a>$3');
  }

  /**
   * Fetches all entries of the month specified by the given date and appends
   * them to the container of the events calendar.
   */
  const updateEventsCalendarBody = (date) => {
    // first clear body
    const calendarBody = $('#events-calendar .body')
    calendarBody.empty();

    // fetch new events and set result
    Rails.ajax({
      url: `events/in_month/${date.toString()}.json`,
      type: 'GET',
      success: function(data) {
        if (!data || data.length == 0) {
          return calendarBody.html('<div class="event-calendar-item text-center">Keine Termine verfügbar</div>')
        }

        const today = new Date();

        data.forEach((event) => {

          // determine if event is in the past for colorizing cells
          let isInPast = false;
          const eventStartDate = new Date(event['start_date']);
          const eventEndDate = new Date(event['end_date']);
          if (eventStartDate && eventEndDate) {
            isInPast = eventStartDate < today && eventEndDate < today;
          } else if (eventStartDate && !eventEndDate) {
            isInPast = eventStartDate < today;
          } else if (!eventStartDate && eventEndDate) {
            isInPast = eventEndDate < today;
          }

          calendarBody.append(`
        <div class="news-trigger d-flex flex-column ${isInPast ? 'past-event-entry' : 'future-event-entry' }" data-notification-id="${event['notification_id']}">
            <div class="d-flex flex-row w-100">
               <div class="date-col d-flex flex-row">
                    <i class="fas fa-calendar-check mr-2"></i>
                    <div class="date-container date-col">${eventDateHTML(event)}</div>
                </div>
              <div class="name-container">${event['name']}</div>
            </div>
            <div class="d-flex flex-row ${ !event['location'] ? 'd-none' : '' }">
               <i class="fas fa-map-marker mr-2"></i>
               <div class="location-container">${eventLocationHTML(event['location'])}</div>
            </div>
        </div>

          `)
        })

        $('#events-calendar .news-trigger').click(onClickNotification)
      },
      error: function(data) { }
    });
  }

  /**
   * Called if the user clicks on the button to switch to the prevuious month of an events calendar.
   * Changes the current month by manipulating the months container attribute.
   * Also fetches the events for this month and updates the events body to show that events.
   */
  $('#events-calendar .previous-month-container').click(() => {
    const currentMonthContainer = $('#events-calendar .current-month-container');

    // Update the month in the data field (month is zero based here)
    const currentMonthDate = new Date(currentMonthContainer.data('monthdate'))
    currentMonthDate.setMonth(currentMonthDate.getMonth() - 1)
    currentMonthContainer.data('monthdate', currentMonthDate.toString())

    // Update the name in the container
    currentMonthContainer.html(`${MONTHS[currentMonthDate.getMonth()]} ${currentMonthDate.getFullYear()}`)

    // Update the entries
    updateEventsCalendarBody(currentMonthDate)
  });

  /**
   * Called if the user clicks on the button to switch to the next month of an events calendar.
   * Changes the current month by manipulating the months container attribute.
   * Also fetches the events for this month and updates the events body to show that events.
   */
  $('#events-calendar .next-month-container').click(() => {
    const currentMonthContainer = $('#events-calendar .current-month-container');

    // Update the month in the data field (month is zero based here)
    const currentMonthDate = new Date(currentMonthContainer.data('monthdate'))
    currentMonthDate.setMonth(currentMonthDate.getMonth() + 1)
    currentMonthContainer.data('monthdate', currentMonthDate.toString())

    // Update the name in the container
    currentMonthContainer.html(`${MONTHS[currentMonthDate.getMonth()]} ${currentMonthDate.getFullYear()}`)

    // Update the entries
    updateEventsCalendarBody(currentMonthDate)
  });

  // if this is a page having a calendar, update the entries
  if ($('#events-calendar').length > 0) {
    updateEventsCalendarBody(new Date())
  }

  /**
   * Registers an event listener that hides the sidebar if the element is clicked.
   * This is used if the user clicks on some tab in the lms that has tables.
   */
  $('.onclick-hide-sidebar').click(() => {
    const sidebarContainer = $('#article-sidebar-container')
    const contentContainer = $('#article-content-container')

    // already hidden
    if (sidebarContainer.hasClass('d-none')) return;

    // hide sidebar
    sidebarContainer.removeClass('col-xl-4')
    sidebarContainer.addClass('d-none')

    // stretch content
    contentContainer.removeClass('col-xl-8')
    contentContainer.addClass('col-xl-12')
  });

  /**
   * Registers an event listener that shows the sidebar if the element is clicked.
   * This is used if the user clicks on some tab in the lms that has no tables.
   * If the user previously clicked on some tab with tables, the sidebar was hidden.
   * This method reverts this change.
   */
  $('.onclick-show-sidebar').click(() => {
    const sidebarContainer = $('#article-sidebar-container')
    const contentContainer = $('#article-content-container')

    // allready visible
    if (!sidebarContainer.hasClass('d-none')) return;

    // show sidebar
    sidebarContainer.addClass('col-xl-4')
    sidebarContainer.removeClass('d-none')

    // shrink content
    contentContainer.removeClass('col-xl-12')
    contentContainer.addClass('col-xl-8')
  });

  /**
   * Inputs in forms having the class empty-submit-disabled disable the submit habing the class
   * disable-on-empty if they are empty.
   * If they are not empty, they will enable it. Additionally the is-invalid class will be added to the input
   * if the content is empty, hence the input will be highlighted.
   */
  $('.empty-submit-disabler').keyup(function(element) {
      if (!$(this).val()) {
        $(this).addClass('is-invalid');
        $('.disable-on-empty').prop('disabled', true)
      } else {
        $(this).removeClass('is-invalid');
        $('.disable-on-empty').prop('disabled', false)
      }
  });

  /**
   * Changes all a tags in the section of the specified element to target: _blank.
   */
  $('.change-links-target-to-blank a').each(function () {
    $(this).attr('target', '_blank')
  })
};


var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);// Support component names relative to this directory:


$(document).on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
  mountSelect2Fields()

  const itemNode = insertedItem.get()[0];
  if (!itemNode) return;

  ReactRailsUJS.mountComponents(itemNode);
});

$(document).on('turbolinks:load', onDocumentReady);
